<form [formGroup]="form">
  <div class="p-fluid p-formgrid p-grid detail">
    <div class="p-field p-col-12 p-md-3">
      <label for="purchasedQuantity">{{ 'Số lượng đã mua'|translate }}</label>
      <p-inputNumber id="purchasedQuantity" formControlName="purchasedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="usedQuantity">{{ 'Số lượng đã sử dụng'|translate }}</label>
      <p-inputNumber id="usedQuantity" formControlName="usedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-5">
      <label for="purchasedTimePeriod">{{ 'Thời gian mua'|translate }}</label>
      <p-inputNumber id="purchasedTimePeriod" formControlName="purchasedTimePeriod"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-1 p-pt-5">
      <span *ngIf="form.get('timeUomId')?.value">({{ form.get('timeUomId')?.value|translate }})</span>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="effectiveDate">{{ 'Ngày bắt đầu sử dụng'|translate }}</label>
      <p-calendar id="effectiveDate" formControlName="effectiveDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="expireDate">{{ 'Ngày hết hạn'|translate }}</label>
      <p-calendar id="expireDate" formControlName="expireDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
</form>
