<p-tabView>
  <p-tabPanel header="Hồ sơ hóa đơn điện tử">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="p-fluid p-formgrid p-grid detail">

        <div class="p-field p-col-12">
          <p-fieldset legend="{{ 'Thông tin đơn vị'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="partyTaxId">{{ 'TaxCode'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="partyTaxId" formControlName="partyTaxId">
                <app-validator [control]="form.get('partyTaxId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="partyName">{{ 'OrganizationName'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="partyName" formControlName="partyName">
                <app-validator [control]="form.get('partyName')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="emailAddress">{{ 'Email'|translate }}</label>
                <input pInputText type="email" id="emailAddress" formControlName="emailAddress" [email]="true">
                <app-validator [control]="form.get('emailAddress')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="phoneNumber">{{ 'Phone'|translate }}</label>
                <input id="phoneNumber" pInputText type="text" formControlName="phoneNumber" [maxlength]="20">
                <app-validator [control]="form.get('phoneNumber')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="faxNumber">{{ 'FaxNumber'|translate }}</label>
                <input pInputText type="text" id="faxNumber" formControlName="faxNumber">
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="webAddress">{{ 'WebAddress'|translate }}</label>
                <input pInputText type="text" id="webAddress" formControlName="webAddress">
                <app-validator [control]="form.get('webAddress')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="stateProvinceGeoId">{{ 'Province'|translate }}<span class="required">*</span></label>
                <p-dropdown id="stateProvinceGeoId" [options]="provinces" [filter]="true" formControlName="stateProvinceGeoId"></p-dropdown>
                <app-validator [control]="form.get('stateProvinceGeoId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <app-county-geo [abstractControl]="form.get('countyGeoId')" [required]="true" [stateProvinceGeoId]="form.get('stateProvinceGeoId')?.value"></app-county-geo>
                <app-validator [control]="form.get('countyGeoId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="address1">{{ 'Địa chỉ đăng ký kinh doanh'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="address1" formControlName="address1">
                <app-validator [control]="form.get('address1')"></app-validator>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="p-field p-col-12">
          <p-fieldset legend="{{ 'Representative'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="representativeName">{{ 'RepresentativeName'|translate }}</label>
                <input pInputText type="text" id="representativeName" formControlName="representativeName">
                <app-validator [control]="form.get('representativeName')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="representativeJobTitle">{{ 'RepresentativeJobTitle'|translate }}</label>
                <input pInputText type="text" id="representativeJobTitle" formControlName="representativeJobTitle">
                <app-validator [control]="form.get('representativeJobTitle')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="representativeEmail">{{ 'Email'|translate }}</label>
                <input pInputText type="email" id="representativeEmail" formControlName="representativeEmail" [email]="true">
                <app-validator [control]="form.get('representativeEmail')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="representativePhoneNumber">{{ 'Phone'|translate }}</label>
                <input id="representativePhoneNumber" pInputText type="text" formControlName="representativePhoneNumber" [maxlength]="20">
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="p-field p-col-6">
          <p-fieldset legend="{{ 'Thông tin cơ quan thuế chủ quản'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-md-6">
                <app-tax-authority [abstractControl]="form.get('taxAuthorityId')" [stateProvinceGeoId]="form.get('stateProvinceGeoId')?.value"></app-tax-authority>
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="p-field p-col-6">
          <p-fieldset legend="{{ 'Thông tin thanh toán'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="bankAccount">{{ 'BankAccount'|translate }}</label>
                <input pInputText type="text" id="bankAccount" formControlName="bankAccount">
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="bankName">{{ 'BankName'|translate }}</label>
                <input pInputText type="text" id="bankName" formControlName="bankName">
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>
      <div class="p-field p-col-12 p-text-right">
        <div *ngIf="!updating">
          <p-button label="{{ 'Edit'|translate }}" icon="pi pi-pencil" styleClass="p-button-raised p-button-warning" (onClick)="update()"
                    *ngIf="AuthService.hasPrivilege('OrganizationInfo', 'OrganizationInfo1', 'UPDATE')"></p-button>
        </div>
        <div *ngIf="updating">
          <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-outlined p-button-secondary" (onClick)="cancelUpdate()"></p-button>
          <p-button label="{{ 'Update'|translate }}" icon="pi pi-check" styleClass="p-button-raised p-ml-2" type="submit" [disabled]="submitted"></p-button>
        </div>
      </div>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Tình trạng hợp đồng">
    <ng-template pTemplate="content">
      <app-consume-review></app-consume-review>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Tờ khai TT78" *ngIf="decreeCircular === 'DecCir78'">
    <ng-template pTemplate="content">
      <app-message-100-accepted></app-message-100-accepted>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Lịch sử thay đổi">
    <ng-template pTemplate="content">
      <app-changelogs></app-changelogs>
    </ng-template>
  </p-tabPanel>
</p-tabView>
