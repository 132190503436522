import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {ConfirmationService} from 'primeng-lts/api';

import {ConfirmService} from './confirm.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit, OnDestroy {

  confirmPopupSubscription?: Subscription;
  confirmDialogSubscription?: Subscription;

  constructor(
    private confirmationService: ConfirmationService,
    private confirmService: ConfirmService
  ) {
  }

  ngOnInit(): void {
    this.confirmPopupSubscription = this.confirmService.getConfirmPopup().subscribe((event) => {
      if (event && event.target) {
        this.confirmationService.close();
        setTimeout(() => {
          this.confirmationService.confirm({
            target: event.target,
            message: event.message,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Có',
            rejectLabel: 'Không',
            accept: event.accept,
            key: 'confirmPopup'
          });
        }, 200);
      }
    });
    this.confirmDialogSubscription = this.confirmService.getConfirmDialog().subscribe((confirmation) => {
      if (confirmation) {
        this.confirmationService.close();
        setTimeout(() => {
          this.confirmationService.confirm(Object.assign(confirmation, {
            acceptLabel: 'Có',
            rejectLabel: 'Không',
            key: 'confirmDialog'
          }));
        }, 200);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.confirmPopupSubscription) {
      this.confirmPopupSubscription.unsubscribe();
    }
    if (this.confirmDialogSubscription) {
      this.confirmDialogSubscription.unsubscribe();
    }
  }

}
