import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {Confirmation} from 'primeng-lts/api';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  private readonly confirmPopup: BehaviorSubject<any>;
  private readonly confirmDialog: BehaviorSubject<any>;

  constructor() {
    this.confirmPopup = new BehaviorSubject<any>(null);
    this.confirmDialog = new BehaviorSubject<any>(null);
  }

  getConfirmPopup(): BehaviorSubject<any> {
    return this.confirmPopup;
  }

  setConfirmPopup(event: any) {
    this.confirmPopup.next(event);
  }

  getConfirmDialog(): BehaviorSubject<any> {
    return this.confirmDialog;
  }

  setConfirmDialog(confirmation: Confirmation) {
    this.confirmDialog.next(confirmation);
  }

}
