<div class="p-dropdown p-component" (click)="opDate.toggle($event)">
  <span #input class="p-dropdown-label p-inputtext">
    <ng-container *ngIf="value && value.length === 2">
      {{ value[0]|date:'dd/MM/yyyy' }} - {{ value[1]|date:'dd/MM/yyyy' }}
    </ng-container>
    <ng-container *ngIf="!(value && value.length === 2)">-</ng-container>
  </span>
  <div role="button" class="p-dropdown-trigger">
    <span class="p-dropdown-trigger-icon pi pi-chevron-down"></span>
  </div>
</div>
<p-overlayPanel #opDate [style]="{width: '220px'}">
  <ng-template pTemplate>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="period">{{ 'Period'|translate }}</label>
        <p-dropdown id="period" [options]="periods" [(ngModel)]="period" (ngModelChange)="onPeriodChange()"></p-dropdown>
      </div>
      <div class="p-field p-col-12">
        <label for="fromDate">{{ 'FromDate'|translate }}</label>
        <p-calendar id="fromDate" [(ngModel)]="fromDate" dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="p-field p-col-12">
        <label for="toDate">{{ 'ThruDate'|translate }}</label>
        <p-calendar id="toDate" [(ngModel)]="toDate" dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="p-field p-col-12">
        <p-button icon="pi pi-check" label="{{ 'Done'|translate }}" (onClick)="opDate.hide();updateModel();"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
