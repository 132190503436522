<p-dialog header="Ký điện tử" [(visible)]="showCerts" [style]="{width: '580px'}" [resizable]="false">
  <div class="p-grid">
    <div class="p-col-12">
      <p-table [value]="certs" selectionMode="single" [(selection)]="selectedCert" dataKey="partyClassificationId">
        <ng-template pTemplate="body" let-cert>
          <tr [pSelectableRow]="cert">
            <td style="width: 3rem">
              <p-tableRadioButton [value]="cert"></p-tableRadioButton>
            </td>
            <td>{{ cert.description }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <p-button icon="fa-solid fa-pen-to-square" label="{{ 'Ký'|translate }}" styleClass="p-button-raised" (onClick)="sign()"
              [disabled]="!selectedCert"></p-button>
  </p-footer>
</p-dialog>

<app-sign-progress [display]="displaySignProgress" [onMessage]="reLoadLazy"></app-sign-progress>
