import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeosService {

  private readonly url = 'iam-common/geos';

  constructor(private http: HttpClient) {
  }

  getProvinces(): Observable<any> {
    return this.http.get(`${this.url}/provinces`);
  }

  getAssocs(geoId: string): Observable<any> {
    return this.http.get(`${this.url}/${geoId}/assocs`);
  }

  getTaxAuthorities(geoId: string): Observable<any> {
    return this.http.get(`${this.url}/${geoId}/tax-authority`);
  }

}
