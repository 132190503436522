import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RouteGuard} from './shared/guard/route.guard';

import {EntitiesComponent} from './entities/entities.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)},
  {path: 'tra-cuu/:id', loadChildren: () => import('./pages/tra-cuu/tra-cuu.module').then(mod => mod.TraCuuModule)},
  {path: 'bao-cao-bv', loadChildren: () => import('./pages/bao-cao/bao-cao.module').then(mod => mod.BaoCaoModule)},
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./entities/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'common',
        loadChildren: () => import('./entities/common/common.module').then(mod => mod.CommonModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'customer',
        loadChildren: () => import('./entities/customer/customer.module').then(mod => mod.CustomerModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'manager',
        loadChildren: () => import('./entities/manager/manager.module').then(mod => mod.ManagerModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'}
    ]
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
