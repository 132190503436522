import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

import {GeosService} from '../../../../shared/service/geos.service';

@Component({
  selector: 'app-tax-authority',
  templateUrl: './tax-authority.component.html'
})
export class TaxAuthorityComponent implements OnChanges {

  @Input() abstractControl?: AbstractControl | null;
  @Input() formControl?: FormControl | null;
  @Input() required?: boolean;

  @Input() stateProvinceGeoId?: string;

  taxAuthorities: Array<any> = new Array<any>();

  constructor(
    private geosService: GeosService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['abstractControl']) {
      this.formControl = this.abstractControl as FormControl;
    }
    if (changes['stateProvinceGeoId']) {
      this.loadTaxAuthorities();
    }
  }

  loadTaxAuthorities(): void {
    if (this.stateProvinceGeoId) {
      this.geosService.getTaxAuthorities(this.stateProvinceGeoId).subscribe(({listData}) => {
        this.taxAuthorities = new Array<any>();
        listData.forEach((item: any) => {
          this.taxAuthorities.push({value: item.taxAuthorityId, label: item.description});
        });
      });
    }
  }

}
