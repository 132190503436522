import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {Enums} from '../../../../shared/enums';

export const PERIOD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IamPeriodComponent),
  multi: true
};

@Component({
  selector: 'iam-period',
  templateUrl: './iam-period.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PERIOD_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focused'
  }
})
export class IamPeriodComponent implements AfterContentInit, ControlValueAccessor {

  @Input() defaultPeriod?: string;

  value: any;

  onModelChange: Function = () => {
  };
  onModelTouched: Function = () => {
  };

  focused?: boolean;

  period?: string;
  periods: Array<any> = Enums.Periods;

  fromDate: any;
  toDate: any;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngAfterContentInit(): void {
    if (this.defaultPeriod) {
      this.period = this.defaultPeriod;
    } else {
      this.period = this.periods[0].value;
    }
    this.onPeriodChange(true);
  }

  writeValue(value: any): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  get filled(): boolean {
    return (this.value != null && this.value.toString().length > 0)
  }

  updateModel(): void {
    this.value = [this.fromDate, this.toDate];
    this.onModelChange(this.value);

    this.onModelTouched();
  }

  onPeriodChange(updateModel?: boolean): void {
    const {fromDate, toDate} = Enums.getPeriodValue(this.period);

    this.fromDate = fromDate;
    this.toDate = toDate;
    if (updateModel) {
      this.updateModel();
    }
  }

}
