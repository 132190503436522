import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {MenuItem} from 'primeng-lts/api';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private static readonly breadcrumb = new BehaviorSubject<string>('');
  private static readonly buttons = new BehaviorSubject<Array<MenuItem>>([]);

  static getBreadcrumb(): BehaviorSubject<string> {
    return this.breadcrumb;
  }

  static setBreadcrumb(breadcrumb: string): void {
    this.breadcrumb.next(breadcrumb);
  }

  static getButtons(): BehaviorSubject<Array<MenuItem>> {
    return this.buttons;
  }

  static pathname = '';

  static setButtons(buttons: Array<MenuItem>, pathname?: string): void {
    if (pathname) {
      if (this.pathname !== pathname) {
        this.buttons.next(buttons);
      }
    } else {
      this.pathname = location.pathname;
      this.buttons.next(buttons);
    }
  }

}
