import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {ItemsService} from './items.service';

@Component({
  selector: 'app-message-100-accepted',
  templateUrl: './message-100-accepted.component.html'
})
export class Message100AcceptedComponent implements OnInit {

  form = this.fb.group({
    agreementId: [''],
    agreementTypeEnumId: ['', Validators.required],
    locationName: ['', Validators.required],
    agreementNo: ['', Validators.required],
    agreementDate: ['', Validators.required],
    organizationPartyName: [{value: '', disabled: true}],
    organizationPartyTaxId: [{value: '', disabled: true}],
    organizationPartyTaxIdArray: [{value: '', disabled: true}],
    organizationPartyAddr: [''],
    organizationPhoneNumber: [''],
    organizationEmailAddress: [''],
    orgRepName: [''],
    taxAuthorityName: [{value: '', disabled: true}],
    itemTerms: this.fb.group({
      formWithTaxAuthCode: [''],
      formWithoutTaxAuthCode: [''],
      transferTypeWithTaxAuthCode: [''],
      transTacDiaBanKK: [''],
      transTacUbnd: [''],
      transferTypeWithoutTaxAuthCode: [''],
      transNoTacDirect: [''],
      transNoTacIndirect: [''],
      transferMethodDetail: [''],
      transferMethodSummary: [''],
      usingVat: [''],
      usingSale: [''],
      usingOther: [''],
      usingBill: ['']
    }),
    signatureItems: ['', Validators.required],
    statusId: [{value: '', disabled: true}],
    issuedBy: [{value: '', disabled: true}],
    issuedDate: [{value: '', disabled: true}]
  });

  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService
  ) {
  }

  ngOnInit(): void {
    this.itemsService.getMessage100Accepted().subscribe((response) => {
      if (response.organizationPartyTaxId) {
        response.organizationPartyTaxIdArray = response.organizationPartyTaxId.split('');
      }
      if (response.agreementTermList) {
        const itemTerms: any = {};
        response.agreementTermList.forEach((itemTerm: any) => {
          itemTerms[itemTerm.enumCode] = itemTerm.termText === 'Y';
        });
        console.log(itemTerms);
        response.itemTerms = itemTerms;
      }
      this.form.patchValue(response);
      this.form.disable();
    });
  }

  print(): void {
    window.print();
  }

}
