import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-sign-progress',
  templateUrl: './sign-progress.component.html'
})
export class SignProgressComponent implements OnInit, OnChanges, OnDestroy {

  baseWebSocketURL: string = environment.baseWebSocketURL;

  @Input() display?: number;
  @Input() onMessage: () => void = () => {
  };

  header: string = '';
  isSuccess?: string;
  listening?: boolean;

  webSocket?: WebSocket;
  pingInterval: any;

  constructor() {
  }

  ngOnInit(): void {
    this.initWebSocket();
  }

  initWebSocket(): void {
    const origin = location.origin.replace('http', 'ws').replace('https', 'wws');
    this.webSocket = new WebSocket(`${origin}/${this.baseWebSocketURL}`);
    this.webSocket.addEventListener('open', () => {
      this.webSocket?.send('subscribe:SignedResult');
    });

    this.webSocket.onclose = (e) => {
      if (e.reason !== 'unmount') {
        this.initWebSocket();
      }
    }
    this.webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.topic === 'SignedResult') {
        if (this.onMessage) {
          this.onMessage();
        }
        if (this.listening) {
          this.listening = false;
          this.isSuccess = data.message.isSuccess;
          if (this.isSuccess === 'Y') {
            this.header = 'Đã ký thành công!';
          } else {
            this.header = 'Có lỗi xảy ra!';
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listening = true;
    this.isSuccess = undefined;
    this.header = 'Chờ xác nhận chữ ký số...';
  }

  ngOnDestroy(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    if (this.webSocket) {
      this.webSocket.close(3010, 'unmount');
    }
  }

}
