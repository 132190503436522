import {Component, OnInit} from '@angular/core';

import {AuthService} from '../shared/service/auth.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html'
})
export class EntitiesComponent implements OnInit {

  topbarActionClick?: boolean;
  activeTopbarAction?: string;

  constructor() {
  }

  ngOnInit(): void {
    AuthService.init();
  }

  onLayoutClick(): void {
    if (!this.topbarActionClick) {
      this.activeTopbarAction = '';
    }

    this.topbarActionClick = false;
  }

  onTopbarActionClick(event: Event, action: string): void {
    this.topbarActionClick = true;

    if (this.activeTopbarAction === action) {
      this.activeTopbarAction = '';
    } else {
      this.activeTopbarAction = action;
    }
    event.preventDefault();
  }

}
