import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}

@Pipe({name: 'safeResourceUrl'})
export class SafeResourceUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}

@Pipe({name: 'paddedNumber'})
export class PaddedNumberPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): SafeHtml {
    let length = args ? args : 7;
    if (value) {
      return value.toString().padStart(length, '0');
    }
    return '';
  }

}
