<p-table [value]="items" [columns]="localCols" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
         [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
         (onLazyLoad)="loadLazy($event)" [dataKey]="dataKey || ''" selectionMode="single" [(selection)]="selectedItem"
         styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines iam-datatable iam-datatable-scrollable-x iam-datatable-scrollable-y"
         [resizableColumns]="true" (onColResize)="onColResize($event)">
  <ng-template pTemplate="caption">
    <div class="table-header">
      <div class="p-grid">
        <div class="p-col-9 p-pb-0">{{ totalRecords|number }} {{ header|translate }}</div>
        <div class="p-col-3 p-pb-0 p-text-right">
          <ng-container *ngIf="dynamicColumns">
            <i class="pi pi-cog" (click)="op.toggle($event)"></i>
            <p-overlayPanel #op [style]="{width: '450px'}">
              <ng-template pTemplate>
                <p-table [value]="localCols" (onRowReorder)="saveLocalCols()"
                         styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:3rem"></th>
                      <th>{{ 'Tên cột' }}</th>
                      <th [width]="80">{{ 'Hiển thị' }}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                    <tr [pReorderableRow]="index">
                      <td class="p-text-center">
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                      </td>
                      <td>{{ rowData['header']|translate }}</td>
                      <td class="p-text-center">
                        <p-checkbox [(ngModel)]="rowData['visible']" [binary]="true" (onChange)="saveLocalCols()"></p-checkbox>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div class="p-grid p-mt-2">
                  <div class="p-col-12 p-text-center">
                    <p-button label="{{ 'Reset'|translate }}" icon="pi pi-replay" styleClass="p-button-outlined p-button-secondary p-button-sm"
                              (onClick)="resetCols()"></p-button>
                  </div>
                </div>
              </ng-template>
            </p-overlayPanel>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col [ngStyle]="{'width': indexColumnWidth + 'px', 'min-width': indexColumnWidth + 'px'}" *ngIf="indexColumnWidth">
      <ng-container *ngFor="let col of localCols">
        <col *ngIf="col.visible && !col.fixedWidth" [ngStyle]="{'min-width': col.width + 'px'}">
        <col *ngIf="col.visible && col.fixedWidth" [ngStyle]="{'width': col.width + 'px', 'min-width': col.width + 'px'}">
      </ng-container>
      <col [ngStyle]="{'width': actionColumnWidth + 'px', 'min-width': actionColumnWidth + 'px'}" *ngIf="actionColumnWidth">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr *ngIf="colGroups">
      <th *ngIf="indexColumnWidth"></th>
      <th *ngFor="let colGroup of colGroups" [colSpan]="colGroup.colspan">{{ colGroup.header|translate }}</th>
      <th *ngIf="actionColumnWidth"></th>
    </tr>
    <tr>
      <th *ngIf="indexColumnWidth">STT</th>
      <ng-container *ngFor="let col of localCols">
        <th *ngIf="col.visible" [pSortableColumn]="col.field" id="th_{{col.field}}" pResizableColumn>{{ col.header|translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <th *ngIf="actionColumnWidth"></th>
    </tr>
    <tr>
      <th *ngIf="indexColumnWidth"></th>
      <ng-container *ngFor="let col of localCols">
        <th *ngIf="col.visible">
          <ng-container [ngSwitch]="col.type">
            <ng-container *ngSwitchCase="'text'">
              <p-columnFilter type="text" [field]="col.field" [matchMode]="col.matchMode" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
            </ng-container>
            <ng-container *ngSwitchCase="'dropdown'">
              <p-columnFilter [field]="col.field" [matchMode]="col.matchMode" [showMenu]="false" [showClearButton]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value" [options]="col.options || []" (onChange)="filter($event.value)" placeholder="-" [showClear]="true" appendTo="body"></p-dropdown>
                </ng-template>
              </p-columnFilter>
            </ng-container>
          </ng-container>
        </th>
      </ng-container>
      <th *ngIf="actionColumnWidth"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-index="rowIndex">
    <tr [pSelectableRow]="rowData">
      <td class="p-text-center" *ngIf="indexColumnWidth">{{ (index + 1)|number }}</td>
      <ng-container *ngFor="let col of localCols">
        <td *ngIf="col.visible">
          <ng-container [ngSwitch]="col.format">
            <ng-container *ngSwitchCase="'text'">
              {{ rowData[col.field] }}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{ rowData[col.field]|date:'dd/MM/yyyy' }}
            </ng-container>
            <ng-container *ngSwitchCase="'date-time'">
              {{ rowData[col.field]|date:'dd/MM/yyyy HH:mm:ss' }}
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <div class="p-text-right">{{ rowData[col.field]|number }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <div class="p-text-center">
                <i class="pi" [ngClass]="{'true-icon pi-check-circle': rowData[col.field] === 'Y', 'false-icon pi-times-circle': rowData[col.field] !== 'Y'}"></i>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'ngClass'">
              <div [class]="col?.ngClass(rowData)">
                {{ rowData[col.field] }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'fromMap'">
              {{ col?.fromMap[rowData[col.field]] }}
            </ng-container>
            <ng-container *ngSwitchCase="'custom'">
              {{ col?.customCell(rowData) }}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <td class="table-actions" (click)="setSelectedItem(rowData)" *ngIf="actionColumnWidth">
        <ng-container *ngFor="let action of rowData['actions']">
          <p-button icon="{{action.icon}}" styleClass="p-button-rounded p-button-outlined p-button-sm p-mr-1 {{action.styleClass}}" [title]="action.title"
                    (onClick)="action.command()" *ngIf="action.command && action.visible !== false"></p-button>
          <p-button icon="{{action.icon}}" styleClass="p-button-rounded p-button-outlined p-button-sm p-mr-1 {{action.styleClass}}" [title]="action.title"
                    [routerLink]="action.routerLink" [queryParams]="action.queryParams" *ngIf="action.routerLink && action.visible !== false"></p-button>
        </ng-container>
        <ng-container *ngIf="rowData['actionMenus']">
          <p-button icon="pi pi-ellipsis-h" styleClass="p-button-rounded p-button-secondary p-button-outlined p-button-sm"
                    (onClick)="menu.toggle($event)" title="{{ 'Other'|translate }}"></p-button>
          <p-menu #menu [popup]="true" [model]="rowData['actionMenus']" appendTo="body" styleClass="width-auto"></p-menu>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
