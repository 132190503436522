import {BehaviorSubject} from 'rxjs';

export class AppSubject {

  private static readonly decreeCircular = new BehaviorSubject<string>('');

  private static readonly menuDisplay = new BehaviorSubject<string>('')

  static getDecreeCircular(): BehaviorSubject<string> {
    return this.decreeCircular;
  }

  static setDecreeCircular(decreeCircular: string): void {
    this.decreeCircular.next(decreeCircular);
  }

  static getMenuDisplay(): BehaviorSubject<string> {
    return this.menuDisplay;
  }

  static setMenuDisplay(menu: string): void {
    this.menuDisplay.next(menu);
  }

}
