import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {ItemsService} from './items.service';
import {validateAllFormFields} from '../../../../shared/components/validator/validator.service';
import {GeosService} from '../../../../shared/service/geos.service';
import {ToastService} from '../../../../shared/components/toast/toast.service';

import {AppSubject} from '../../../../shared/app-subject';
import {Subscription} from 'rxjs';

import {IamComponent} from '../../../abstract/IamComponent';

@Component({
  selector: 'app-organization-info',
  templateUrl: './items.component.html'
})
export class ItemsComponent extends IamComponent implements OnInit, OnDestroy {

  submitted: boolean = false;
  form = this.fb.group({
    partyTaxId: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.@-]*$/)]],
    partyName: ['', Validators.required],
    webAddress: [''],
    emailAddress: [''],
    phoneNumber: [''],
    faxNumber: [''],
    address1: ['', Validators.required],
    stateProvinceGeoId: ['', Validators.required],
    countyGeoId: ['', Validators.required],
    taxAuthorityId: ['', Validators.required],
    taxAuthorityName: [''],
    bankName: [''],
    bankAccount: [''],

    representativeName: [''],
    representativeJobTitle: [''],
    repIdentityCard: [''],
    repIdCardIssuedDate: [''],
    repIdCardIssuedBy: [''],
    representativePhoneNumber: [''],
    representativeEmail: [''],

    customerStatusId: [''],
    customerStatusCode: [''],
    establishedDate: [''],
    decreeAndCircularUsing: [{value: '', disabled: true}]
  });
  provinces: Array<any> = new Array<any>();

  updating?: boolean;

  decreeCircular?: string;
  decreeCircularSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private geosService: GeosService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.decreeCircularSubscription = AppSubject.getDecreeCircular().subscribe((decreeCircular) => {
      if (decreeCircular) {
        this.decreeCircular = decreeCircular;
      }
    });

    const init = [];
    init.push(new Promise<void>((resolve) => {
      this.geosService.getProvinces().subscribe(({listData}) => {
        listData.forEach((item: any) => {
          this.provinces.push({value: item.toGeoId, label: item.geoName});
        });
        resolve();
      });
    }));
    Promise.all(init).then(() => {
      this.load();
    });
  }

  ngOnDestroy(): void {
    if (this.decreeCircularSubscription) {
      this.decreeCircularSubscription.unsubscribe();
    }
  }

  load(): void {
    this.itemsService.get().subscribe((response) => {
      this.form.reset();

      this.form.patchValue(response);
      this.form.disable();
    });
  }

  update(): void {
    this.updating = true;

    this.form.enable();
  }

  cancelUpdate(): void {
    this.updating = false;

    this.form.disable();
  }

  onSubmit(): void {
    if (!this.submitted && validateAllFormFields(this.form)) {
      this.submitted = true;

      this.itemsService.set(this.form.value).subscribe((response) => {
        this.submitted = false;
        if (response && response.customerPartyId) {
          this.toastService.success();
          this.cancelUpdate();
        } else {
          this.toastService.error();
        }
      }, () => {
        this.submitted = false;
      });
    }
  }

}
