import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html'
})
export class ValidatorComponent {

  @Input() control?: AbstractControl | null;

  constructor() {
  }

}
