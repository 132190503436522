import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

import {GeosService} from '../../../../shared/service/geos.service';

@Component({
  selector: 'app-county-geo',
  templateUrl: './county-geo.component.html'
})
export class CountyGeoComponent implements OnChanges {

  @Input() abstractControl?: AbstractControl | null;
  @Input() required?: boolean;

  @Input() stateProvinceGeoId?: string;

  formControl?: FormControl | null;
  counties: Array<any> = new Array<any>();

  constructor(
    private geosService: GeosService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['abstractControl']) {
      this.formControl = this.abstractControl as FormControl;
    }
    if (changes['stateProvinceGeoId']) {
      this.loadCounties();
    }
  }

  loadCounties(): void {
    if (this.stateProvinceGeoId) {
      this.geosService.getAssocs(this.stateProvinceGeoId).subscribe(({listData}) => {
        this.counties = new Array<any>();
        listData.forEach((item: any) => {
          this.counties.push({value: item.toGeoId, label: item.geoName});
        });
      });
    }
  }

}
