import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {LoginService} from '../../../../pages/login/login.service';
import {CertificatedService} from './certificated.service';

import {ToastService} from '../../../../shared/components/toast/toast.service';

@Component({
  selector: 'app-certificated',
  templateUrl: './certificated.component.html'
})
export class CertificatedComponent implements OnInit, OnDestroy {

  certsSubscription?: Subscription;

  displaySignProgress?: number;
  reLoadLazy: any;

  showCerts?: boolean;
  selectedCert: any;
  certs: Array<any> = new Array<any>();
  locationHref?: string;
  hsmUrl?: string;

  constructor(
    private certificatedService: CertificatedService,
    private loginService: LoginService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.certsSubscription = this.certificatedService.certsSubject.subscribe(({certs, locationHref, hsmUrl, reLoadLazy}) => {
      this.locationHref = locationHref;
      this.hsmUrl = hsmUrl;
      this.reLoadLazy = reLoadLazy;

      if (!hsmUrl) {
        this.signByUsb();
      } else {
        if (certs && certs.length) {
          if (certs.length > 1) {
            this.certs = certs;
            this.showCerts = true;
          } else {
            this.selectedCert = certs[0];
            this.sign();
          }
        } else {
          this.selectedCert = null;
          this.signByUsb();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.certsSubscription) {
      this.certsSubscription.unsubscribe();
    }
  }

  sign(): void {
    if (this.selectedCert) {
      switch (this.selectedCert.standardCode) {
        case 'usb-token':
          this.signByUsb();
          break;
        default:
          this.signByHsm();
      }
      this.showCerts = false;
    }
  }

  signByHsm(): void {
    this.certificatedService.signHsm(this.hsmUrl || '').subscribe(({xmlData}) => {
      if (xmlData) {
        this.toastService.success();
      } else {
        this.toastService.error();
      }
      if (this.reLoadLazy) {
        this.reLoadLazy();
      }
    });
  }

  signByUsb(): void {
    this.loginService.getApiKey().subscribe((response) => {
      if (response.token) {
        location.href = this.locationHref || '';
        this.displaySignProgress = Date.now();
      }
    });
  }

}
