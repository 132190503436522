<p-dialog [header]="header" [(visible)]="display" [style]="{width: '50rem'}">

  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="listening"></p-progressBar>

  <div style="width: 20rem; margin: 0 auto;text-align: center;" *ngIf="!listening">
    <i class="pi pi-check-circle" style="font-size: 6rem;color: green;" *ngIf="isSuccess === 'Y'"></i>
    <i class="pi pi-exclamation-circle" style="font-size: 6rem;color: red;" *ngIf="isSuccess === 'N'"></i>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="display=0" label="{{ 'Close'|translate }}" styleClass="p-button-text" *ngIf="!listening"></p-button>
  </ng-template>
</p-dialog>
