import {BreadcrumbService} from '../components/layout/breadcrumb/breadcrumb.service';
import {TranslateService} from '../../shared/translate/translate.service';

import {AuthService} from '../../shared/service/auth.service';

export abstract class IamComponent {

  translateService = new TranslateService();
  BreadcrumbService = BreadcrumbService;
  AuthService = AuthService;

}
