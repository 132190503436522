import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ToastModule} from 'primeng-lts/toast';
import {ConfirmPopupModule} from 'primeng-lts/confirmpopup';
import {ConfirmDialogModule} from 'primeng-lts/confirmdialog';
import {ButtonModule} from 'primeng-lts/button';
import {InputTextModule} from 'primeng-lts/inputtext';
import {DialogModule} from 'primeng-lts/dialog';
import {RippleModule} from 'primeng-lts/ripple';
import {RadioButtonModule} from 'primeng-lts/radiobutton';
import {MenubarModule} from 'primeng-lts/menubar';
import {MessagesModule} from 'primeng-lts/messages';
import {MessageModule} from 'primeng-lts/message';
import {TableModule} from 'primeng-lts/table';
import {MultiSelectModule} from 'primeng-lts/multiselect';
import {CalendarModule} from 'primeng-lts/calendar';
import {DropdownModule} from 'primeng-lts/dropdown';
import {KeyFilterModule} from 'primeng-lts/keyfilter';
import {FieldsetModule} from 'primeng-lts/fieldset';
import {FileUploadModule} from 'primeng-lts/fileupload';
import {EditorModule} from 'primeng-lts/editor';
import {ChartModule} from 'primeng-lts/chart';
import {InputTextareaModule} from 'primeng-lts/inputtextarea';
import {CheckboxModule} from 'primeng-lts/checkbox';
import {AutoCompleteModule} from 'primeng-lts/autocomplete';
import {InputNumberModule} from 'primeng-lts/inputnumber';
import {TabViewModule} from 'primeng-lts/tabview';
import {CardModule} from 'primeng-lts/card';
import {InputMaskModule} from 'primeng-lts/inputmask';
import {SliderModule} from 'primeng-lts/slider';
import {AccordionModule} from 'primeng-lts/accordion';
import {InputSwitchModule} from 'primeng-lts/inputswitch';
import {SelectButtonModule} from 'primeng-lts/selectbutton';
import {ToggleButtonModule} from 'primeng-lts/togglebutton';
import {OrderListModule} from 'primeng-lts/orderlist';
import {OverlayPanelModule} from 'primeng-lts/overlaypanel';
import {MenuModule} from 'primeng-lts/menu';

import {MessageService} from 'primeng-lts/api';
import {ConfirmationService} from 'primeng-lts/api';

import {
  SafeHtmlPipe,
  SafeResourceUrlPipe,
  PaddedNumberPipe
} from './pipe';
import {TranslatePipe} from './translate/translate.pipe';

import {ConfirmComponent} from './components/confirm/confirm.component';
import {ProgressComponent} from './components/progress/progress.component';
import {ToastComponent} from './components/toast/toast.component';
import {ValidatorComponent} from './components/validator/validator.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    PaddedNumberPipe,
    TranslatePipe,

    ConfirmComponent,
    ProgressComponent,
    ToastComponent,
    ValidatorComponent
  ],
  imports: [
    CommonModule,

    ToastModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    ButtonModule,
    InputTextModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ButtonModule,
    InputTextModule,
    DialogModule,
    RippleModule,
    RadioButtonModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TableModule,
    CalendarModule,
    MultiSelectModule,
    DropdownModule,
    KeyFilterModule,
    FieldsetModule,
    FileUploadModule,
    EditorModule,
    ChartModule,
    InputTextareaModule,
    CheckboxModule,
    AutoCompleteModule,
    InputNumberModule,
    TabViewModule,
    CardModule,
    InputMaskModule,
    SliderModule,
    AccordionModule,
    InputSwitchModule,
    SelectButtonModule,
    ToggleButtonModule,
    OrderListModule,
    OverlayPanelModule,
    MenuModule,

    SafeHtmlPipe,
    SafeResourceUrlPipe,
    PaddedNumberPipe,
    TranslatePipe,

    ConfirmComponent,
    ProgressComponent,
    ToastComponent,
    ValidatorComponent
  ]
})
export class SharedModule {
}
