import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: `root`
})
export class CertificatedService {

  readonly certsSubject: Subject<any>;

  constructor(
    private http: HttpClient
  ) {
    this.certsSubject = new Subject<any>();
  }

  getSignatureClassify(): Observable<any> {
    return this.http.get(`iam-common/signature-classify`);
  }

  signHsm(url: string): Observable<any> {
    return this.http.put(url, {});
  }

  run(params: any): void {
    const {locationHref, hsmUrl, reLoadLazy} = params;
    this.getSignatureClassify().subscribe(({data}) => {
      this.certsSubject.next({certs: data, locationHref, hsmUrl, reLoadLazy});
    });
  }

}
