import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {ProgressService} from './progress.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit, OnDestroy {

  progress: boolean = false;

  progressSubscription?: Subscription;

  constructor(private service: ProgressService) {
  }

  ngOnInit(): void {
    this.progressSubscription = this.service.getProgress().subscribe((progress) => {
      this.progress = progress;
    });
  }

  ngOnDestroy(): void {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
  }

}
