/*-----------------------------------------------------------------------------
 - Created by Hoanm on 10/19/19 11:12 AM.                                     -
 -----------------------------------------------------------------------------*/
import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {Message} from 'primeng-lts/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private readonly message: BehaviorSubject<Message>;

  constructor() {
    this.message = new BehaviorSubject<Message>({});
  }

  getMessage(): BehaviorSubject<Message> {
    return this.message;
  }

  setMessage(message: Message): void {
    this.message.next(message);
  }

  success(): void {
    this.setMessage({severity: Severity.SUCCESS});
  }

  error(): void {
    this.setMessage({severity: Severity.ERROR});
  }

}

export const Severity = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
};
