export default {
  Settings: {
    en: 'Settings',
    vi: 'Cài đặt'
  },
  ComponentScale: {
    en: 'Component Scale',
    vi: 'Kích thước'
  },
  LayoutMode: {
    en: 'Layout Mode',
    vi: 'Giao diện'
  },
  Light: {
    en: 'Light',
    vi: 'Sáng'
  },
  Dark: {
    en: 'Dark',
    vi: 'Tối'
  },
  MenuMode: {
    en: 'Menu Mode',
    vi: 'Menu'
  },
  Static: {
    en: 'Static',
    vi: 'Dọc'
  },
  Horizontal: {
    en: 'Horizontal',
    vi: 'Ngang'
  },
  Slim: {
    en: 'Slim',
    vi: 'Thu nhỏ'
  },
  MenuThemes: {
    en: 'Menu Themes',
    vi: 'Màu Menu'
  },
  TopbarThemes: {
    en: 'Topbar Themes',
    vi: 'Màu Topbar'
  },
  ComponentThemes: {
    en: 'Component Themes',
    vi: 'Màu chủ đạo'
  },
  Close: {
    en: 'Close',
    vi: 'Đóng'
  },
  ToggleMenu: {
    en: 'Toggle Menu',
    vi: 'Ẩn / Hiện Menu'
  }
};
