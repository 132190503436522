<div class="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center">

  <p-breadcrumb [model]="items" [home]="home" styleClass="layout-breadcrumb p-pl-4 p-py-2"></p-breadcrumb>

  <div class="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3 p-pb-1">
    <ng-container *ngFor="let button of buttons">
      <p-button [icon]="button.icon || ''" styleClass="p-button-rounded p-button-outlined p-mr-1 {{ button.styleClass }}"
                [routerLink]="button.routerLink" *ngIf="button.routerLink" label="{{ button.tooltip|translate }}"></p-button>
      <p-button [icon]="button.icon || ''" styleClass="p-button-rounded p-button-outlined p-mr-1 {{ button.styleClass }}"
                (onClick)="button.command()" *ngIf="button.command" label="{{ button.tooltip|translate }}"></p-button>
    </ng-container>
  </div>
</div>
